.custom-card {
    font-family: Lato, sans-serif;
    width: 100%;
    box-shadow: 0 1px 3px 1px rgba(186, 186, 186, 0.15), 0 1px 2px 0 rgba(22, 22, 22, 0.3);
    background-color: $white;
    border-radius: 8px;

    &__header {
        background-color: $blue-1;
        color: $white;
        padding: 10px;
        letter-spacing: 0.15px;
        font-size: 19px;
        font-weight: 500;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        display: flex;
        justify-content: space-between;
    }

    &__body {
        background-color: $white;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 16px;
    }
}
