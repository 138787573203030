@import '~bootstrap/scss/bootstrap';
@import './styles/common.scss';
@import './styles/custom.scss';
@import './styles/features.scss';
@import './styles/components.scss';
@import './styles/fonts.scss';

.col-10 {
    width: 80%;
}
