.table-custom_cell {
    padding: 16px 8px;
    font-size: 15px;
    font-family: Lato, sans-serif;

    img {
        display: inline-block;
        height: 64px;
        width: 64px;
        border-radius: 8px;
        object-fit: cover;
    }
}
