.input-files {
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: Lato, sans-serif;

    &.input-center {
        display: inline-block;
    }

    &__container {
        width: 64px;
        height: 64px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &--image {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23C3C9D0FF' stroke-width='1' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        }

        &--video {
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%234568F2FF' stroke-width='1' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            background-color: $bg-blue;
        }

        .input-hold {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            outline: 0;
            cursor: pointer;
            opacity: 0;
        }

        .input-frame {
            display: flex;
            flex-direction: column;

            span {
                color: $blue-1;
            }
        }
    }

    .input-note {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            color: $gray;
            font-size: 13px;
        }
    }
}
