.auth {
    position: relative;
    height: 100vh;
    overflow: hidden;

    &-layout {
        &--topLeft {
            position: absolute;
            top: 0;
            left: 0;

            img {
                width: 200px;
                height: 220px;
                object-fit: cover;
                z-index: -1;
            }
        }

        &--bottom {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            img {
                width: 500px;
                height: 200px;
                object-fit: cover;
            }
        }

        &--right {
            position: absolute;
            right: 0;
            width: 49vw;
            height: 100vh;
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    &-content {
        // min-width: 1000px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 150px;

        &__intro {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                // width: 100%;
                width: 96px;
                height: 81px;
                object-fit: cover;
                margin-bottom: 12px;
            }

            p {
                text-transform: uppercase;
                font-weight: 700;
                font-family: Helvetica, sans-serif;

                &.auth-title {
                    color: $blue;
                    font-size: 16px;
                }

                &.auth-subTitle {
                    color: $blue-1;
                    font-size: 14px;
                }
            }
        }

        .auth-form {
            width: 400px;
            font-family: 'NunitoSans-SemiBold', sans-serif;
            form {
                text-align: center;

                h4 {
                    font-size: 32px;
                    font-weight: 400;
                    font-family: Lato, sans-serif;
                    margin-bottom: 32px;
                    color: $black-2;

                    &.text-success {
                        font-size: 19px;
                        font-weight: 500;
                        color: $success-1;
                        margin-bottom: 16px;
                    }
                }

                .form-content {
                    gap: 32px;
                    margin-bottom: 10px;

                    &__helper {
                        color: $gray;

                        &.Mui-error {
                            color: $red-button;
                        }
                    }
                }

                .form-helper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;

                    span {
                        font-family: Lato, sans-serif;
                    }

                    &__variant {
                        color: $blue-3;
                        font-size: 15px;
                        letter-spacing: 0.25px;
                        line-height: 1.2;
                        font-family: Lato, sans-serif;
                        cursor: pointer;
                        isolation: isolate;
                        z-index: 10;
                    }
                }

                .form-subTitle {
                    margin-bottom: 32px;
                    font-family: Helvetica, sans-serif;
                    font-size: 15px;
                    line-height: 1.2;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: $black-1;
                }

                span {
                    &.text-redirect {
                        color: $gray;

                        &__timer {
                            color: $blue-1;
                        }
                    }

                    &.text-variant {
                        color: $blue-1;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                img {
                    width: 300px;
                    height: 300px;
                }
            }
        }
    }
}
