.MuiPagination-root {
    .MuiPagination-ul {
        .MuiPaginationItem-rounded {
            border-radius: 8px;
        }
        .MuiPaginationItem-page {
            color: $gray-4;
        }
        .Mui-selected {
            background-color: $blue-1;
            color: $white;
        }
    }
}